var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"event-detail"},[_c('HeaderComponent'),_c('TopPlanetComponent',{attrs:{"imgPath":require("@/assets/imgs/event/top5.png"),"title":"イベント情報"}}),_c('div',{staticClass:"event-detail-inner"},[_c('p',{staticClass:"event-detail-inner-title font-bunkyu-midashi"},[_vm._v(_vm._s(_vm.eventName))]),_c('img',{staticClass:"event-detail-inner-banner",attrs:{"src":_vm.bannerImg}}),_c('div',{staticClass:"event-detail-inner-about font-bunkyu-midashi"},[_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.event == 'oc'),expression:"event == 'oc'"}],staticClass:"about-oc"},[_vm._m(0),_vm._m(1)]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.event == 'geisai'),expression:"event == 'geisai'"}],staticClass:"about-geisai"},[_vm._m(2),_vm._m(3),_vm._m(4)]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.event == 'sotsusei'),expression:"event == 'sotsusei'"}],staticClass:"about-sotsusei"},[_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"event-detail-inner-otherevent font-bunkyu-midashi"},[_c('p',{staticClass:"otherevent-title"},[_vm._v("その他のイベント")]),_c('div',{staticClass:"circles"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.event != 'oc'),expression:"event != 'oc'"}],staticClass:"circle-wrapper"},[_vm._m(7)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.event != 'sotsusei'),expression:"event != 'sotsusei'"}],staticClass:"circle-wrapper"},[_vm._m(8)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.event != 'geisai'),expression:"event != 'geisai'"}],staticClass:"circle-wrapper"},[_vm._m(9)])])])]),_c('FooterComponent')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" 今年の多摩美術大学オープンキャンパスは、夏と秋の2回にわたって開催。さらにWEB版オープンキャンパスも開催します。 本学で学ぶ分野を理解する機会、また進路学習の一環としてご活用ください。 （コロナ感染症の状況によって予定が変更となる可能性があります。必ず事前にオープンキャンパス特設サイトをご確認下さい） ")]),_c('p',[_vm._v(" 情報デザインコースでは授業で制作した作品の展示をメインに、情報デザインコース進学相談会も開催されました。 またオンラインの学科紹介、入試解説（2021）はいつでも閲覧することができます。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-area"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/oc1.jpg")}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/oc2.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" 本学の学園祭は、その特質から「芸術祭」と称して、毎秋、全学生の自主的な創意と協力によって行われています。 日頃の制作の成果を発表する展示や、内装外装にも力の入った模擬店は一般の方々にも人気を呼んでいます。 その他、特設ステージでのゲストによる講演やパフォーマンス、学生の企画したイベントも行われています。 （開催内容は年度により異なります。詳しくは下記「芸術祭の公式サイト」をご覧ください） ")]),_c('p',[_vm._v(" 情報デザインコースの学生が開催しているフリーマーケットもありますのでぜひお立ち寄りください。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("進学相談会 in 芸術祭")]),_c('p',[_vm._v(" 芸術祭期間中、進学相談コーナーを設けます。入試参考作品展示、大学職員による入試概要や奨学金などの進学に関する個別相談、各種資料の配布を行います。 （教員の参加がないため、持参作品等の講評は行いません） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-area"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/geisai1.jpg")}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/geisai2.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" 本年度の卒業・修了制作展は、学科･専攻ごとに1月と3月の2回に分けて、八王子キャンパスにて開催いたします。 また、学生たちが自主的に企画した独自の展示や個人･グループによる展示が、都内および神奈川県内のアートスペース 各所で行われる予定です（スケジュールは今後随時公開します）。 現代社会へ向けて発信する学生たちのデビュー作を、ぜひ会場で体感してください。 みなさまのご来場を心よりお待ちしております。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-area"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/sotsusei1.jpg")}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/sotsusei2.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle"},[_c('a',{attrs:{"href":"/event-detail/oc"}},[_vm._v("オープンキャンパス")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle"},[_c('a',{attrs:{"href":"/event-detail/sotsusei"}},[_vm._v("卒業制作展")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle"},[_c('a',{attrs:{"href":"/event-detail/geisai"}},[_vm._v("芸術祭")])])
}]

export { render, staticRenderFns }