var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"bus"},[_c('HeaderComponent'),_c('TopPlanetComponent',{attrs:{"imgPath":require("@/assets/imgs/event/top5.png"),"title":"路線バス利用案内"}}),_c('div',{staticClass:"bus-inner font-bunkyu-midashi"},[_c('div',{staticClass:"bus-inner-selector"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.station == 'hashimoto'),expression:"station == 'hashimoto'"}]},[_c('p',{staticClass:"title"},[_vm._v("橋本駅バスダイヤ")]),_c('div',{staticClass:"circles"},[_c('div',{staticClass:"circle",on:{"click":() => {
            this.station = 'hashimoto';
            this.direction = 'from';
            this.$router.push('/bus/hashimoto/from');
          }}},[_vm._v("橋本駅発")]),_c('div',{staticClass:"circle",on:{"click":() => {
            this.station = 'hashimoto';
            this.direction = 'to';
            this.$router.push('/bus/hashimoto/to');
          }}},[_vm._v("多摩美術"),_c('br'),_vm._v("大学発")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.station == 'hachioji'),expression:"station == 'hachioji'"}]},[_c('p',{staticClass:"title"},[_vm._v("八王子駅バスダイヤ")]),_c('div',{staticClass:"circles"},[_c('div',{staticClass:"circle",on:{"click":() => {
            this.station = 'hachioji';
            this.direction = 'from';
            this.$router.push('/bus/hachioji/from');
          }}},[_vm._v("八王子駅発")]),_c('div',{staticClass:"circle",on:{"click":() => {
            this.station = 'hachioji';
            this.direction = 'to';
            this.$router.push('/bus/hachioji/to');
          }}},[_vm._v("多摩美術"),_c('br'),_vm._v("大学発")])])])]),_c('div',{staticClass:"bus-inner-dia"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.station == 'hashimoto' && _vm.direction == 'from'),expression:"station == 'hashimoto' && direction == 'from'"}]},[_c('p',{staticClass:"title"},[_vm._v("橋本駅北口発→多摩美術大学行（橋75系統）")]),_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/bus_from_hashimoto.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.station == 'hashimoto' && _vm.direction == 'to'),expression:"station == 'hashimoto' && direction == 'to'"}]},[_c('p',{staticClass:"title"},[_vm._v("多摩美術大学発→橋本駅北口行（橋75系統）")]),_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/bus_to_hashimoto.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.station == 'hachioji' && _vm.direction == 'from'),expression:"station == 'hachioji' && direction == 'from'"}]},[_c('p',{staticClass:"title"},[_vm._v("八王子駅南口発→急行 多摩美術大学行（八70系統）")]),_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/bus_from_hachioji.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.station == 'hachioji' && _vm.direction == 'to'),expression:"station == 'hachioji' && direction == 'to'"}]},[_c('p',{staticClass:"title"},[_vm._v("多摩美術大学発→急行 八王子駅南口行き（八70系統）")]),_c('img',{attrs:{"src":require("@/assets/imgs/event/imgs/bus_to_hachioji.png")}})])]),_vm._m(0),_c('OtherEventComponent',{staticClass:"event"})],1),_c('FooterComponent')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("※橋本駅北口－多摩美術大学間は、リーフィア町田小山ヶ丘を経由する別系統も運行しています。 ［所要時間＝15分］ ")]),_c('p',[_vm._v(" ※橋本駅北口－多摩美術大学間の定期券をお持ちの場合でも、リーフィア町田小山ヶ丘経由の系統を ご利用の場合は、久保ヶ谷戸・坂下・リーフィア町田小山ヶ丘のバス停での途中下車は出来ません。 ")])])
}]

export { render, staticRenderFns }