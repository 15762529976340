export const questions = [
  {
    index: 0,
    name: "画力",
    questionTexts: [
      "絵を描く事は好きですか？"
    ]
  },
  {
    index: 1,
    name: "脳力",
    questionTexts: [
      "物事を深く考えるのは好きですか？"
    ]
  },
  {
    index: 2,
    name: "コミュニケーション能力",
    questionTexts: [
      "人と話すことは好きですか？"
    ]
  },
  {
    index: 3,
    name: "思考力",
    questionTexts: [
      "なにかするとき効率を求めますか？"
    ]
  },
  {
    index: 4,
    name: "表現力",
    questionTexts: [
      "自分を表現するのが好きですか？",
      "人に説明するのは慣れていますか？"
    ]
  }
];