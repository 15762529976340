export const teachers = {
  uemura: {
    name: "植村朋弘教授",
    img: require("@/assets/imgs/shindan/teachers/uemura.jpg"),
    about: "筑波大学大学院芸術研究科修了後、株式会社GKにて製品デザインをおこなう。2005年、筑波大学にて博士号を取得。われわれ自身の「経験」に着目し、人びとが「経験」をかたちづくるしくみを手掛かりにUI・UXデザインの教育について研究している。またワークショップやプロジェクト学習における”表現を通した学びと学習環境”に関する研究をおこなっており、学びの活動を観察記録し分析するソフトウエアの研究開発をおこなっている。特に幼児の遊びに着目し、人間の原初的な”表現による学び”について探究している。いくつかの科学研究費補助金を受け、他分野研究者と協働で研究をおこない、学会発表及び論文発表をおこなっている。",
    kamoku: [
      "UI/UXデザイン演習",
      "デザイン思考-経験デザインⅠ,Ⅱ (植村ゼミ)",
      "ラーニングとデザインゼミ",
      "卒業研究制作-大学院情報デザイン特論",
      "大学院デザイン研究「経験デザイン研究グループ」"
    ],
    igaina: [
      "好きな食べ物  /  シュークリーム、チーズケーキ",
      "嫌いな食べ物  /  伊達巻",
      "無人島に一つだけ持って行くなら  /  ナイフ",
      "休日何をしているか  /  寝てるor洗車(3ヶ月に1回くらい)",
      "何に癒されるか  /  家で飼っている柴犬"
    ]
  },
  hayashi: {
    name: "林響太朗講師",
    img: require("@/assets/imgs/shindan/teachers/noimg.png"),
    kamoku: [
      "演出表現演習"
    ],
  },
  osada: {
    name: "長田純一講師",
    img: require("@/assets/imgs/shindan/teachers/osada.jpg"),
    about: "1970年東京都生まれ。多摩美術大学大学院美術研究科デザイン専攻修了。1995年（株）NECデザイン（現：日本電気株式会社クリエイティブデザインセンター）入社。 1997年よりNEC中央研究所でコミュニケーションロボットPaPeRo（パペロ）の研究開発をデザイン面で牽引。その後クリエイティブマネージャーとして、ICTによる社会課題の解決や顧客との価値共創などNECの新規事業や未来ビジョンデザイン・UXデザインを牽引。2019年より東北芸術工科大学プロダクトデザイン学科にて、UXデザイン・サービスデザインを担当し、2022年より公立はこだて未来大学情報アークテクチャ学科情報デザインコース准教授。",
    kamoku: [
      "コンテンツデザイン演習"
    ],
  },
  aida: {
    name: "会田大也講師",
    img: require("@/assets/imgs/shindan/teachers/noimg.png"),
    kamoku: [
      "ストーリーテリング演習",
      "社会デザイン論"
    ],
  },
  aoki: {
    name: "青木聖也講師",
    img: require("@/assets/imgs/shindan/teachers/aoki.jpg"),
    kamoku: [
      "プログラミング演習"
    ],
  },
  ando: {
    name: "安藤僚子講師",
    img: require("@/assets/imgs/shindan/teachers/ando.jpg"),
    about: "1999年多摩美術大学美術学部建築科卒業。空間デザイナー。合同会社デザインムジカ代表。ファッションや飲食などの店舗設計を中心に、演劇や科学展示などの会場構成、ディスプレイやアートインスタレーションなど空間にまつわる幅広いジャンルで活動している。主な作品に「スポーツタイムマシン」（メディアインスタレーション／2014年第17回文化庁メディア芸術祭エンターテインメント部門優秀賞／アルスエレクトロニカ2014(オーストリア)インタラクティブアート部門入選）、日本科学未来館常設展「未来逆算思考」（空間設計・アートディレクション）など。著書に「TOKYOインテリアツアー」(LIXIL出版社)など。",
    kamoku: [
      "展示計画演習"
    ],
    igaina: [
      "座右の銘  /  諸行無常",
      "好きな食べ物  /  水餃子",
      "嫌いな食べ物  /  奈良漬け",
      "1つだけ無人島に持っていくなら  /  ナイフ",
      "休日何をしているか  /  洗濯、美術館、仕事",
      "何に癒されるか  /  猫と植物育てと旅行"
    ]
  },
  tsukada: {
    name: "塚田哲也講師",
    img: require("@/assets/imgs/shindan/teachers/tsukada.jpg"),
    about: "秀親と塚田哲也の2人で1993年に結成。新しい文字の概念を探る実験的タイポグラフィ集団。2015 年『字字字』（ギンザ・グラフィック・ギャラリー）、2019年『文ッ字』（町田市民文学館 ことばらんど）にて個展を開催。2017 年『タイポ ジャンチ』（ソウル）、2018 年『漢字展』（香港）『デザインあ 展』（富山県美術館/ 日本科学未来館ほか）などにも参加。主著書に『TYPECARDPLAYBOOK』（ACTAR）『大日本字』（誠文堂新光社）、絵本『なんでも もじもじ』（福音館書店）、もじかけえほん『かな』（偕成社）",
    kamoku: [
      "文字表現演習"
    ]
  },
  hide: {
    name: "秀親講師",
    img: require("@/assets/imgs/shindan/teachers/hide.jpg"),
    about: "秀親と塚田哲也の2人で1993年に結成。新しい文字の概念を探る実験的タイポグラフィ集団。2015 年『字字字』（ギンザ・グラフィック・ギャラリー）、2019年『文ッ字』（町田市民文学館 ことばらんど）にて個展を開催。2017 年『タイポ ジャンチ』（ソウル）、2018 年『漢字展』（香港）『デザインあ 展』（富山県美術館/ 日本科学未来館ほか）などにも参加。主著書に『TYPECARDPLAYBOOK』（ACTAR）『大日本字』（誠文堂新光社）、絵本『なんでも もじもじ』（福音館書店）、もじかけえほん『かな』（偕成社）",
    kamoku: [
      "文字表現演習"
    ]
  },
  fujimoto: {
    name: "藤本直明講師",
    img: require("@/assets/imgs/shindan/teachers/fujimoto.jpg"),
    about: "東京工業大学理学部物理学科卒業(素粒子物理学)。インタラクティブな空間デザイン、画像認識・音響処理・物理演算などの技術を駆使したソフトウェアの開発や、電子回路の設計制作、「体験」をテーマにした作品制作を行う。主な作品:《ImmersiveShadow》《覗かれ穴》《新しい過去》主な著作:『電脳Arduinoでちょっと未来を作る』(共著/CQ出版)、『MakingThingsSee(日本語版)』(監修/オライリー・ジャパン)",
    kamoku: [
      "インタラクティブデザイン演習"
    ]
  },
  narita: {
    name: "成田逹哉講師",
    img: require("@/assets/imgs/shindan/teachers/narita.jpg"),
    kamoku: [
      "3D表現演習"
    ],
    igaina: [
      "座右の銘  /  興味本位",
      "好きな食べ物  /  梨",
      "嫌いな食べ物  /  なし",
      "1つだけ無人島に持っていくなら  /  道具",
      "休日何をしているか  /  開拓",
      "何に癒されるか  /  恋人と過ごす時間"
    ]
  },
  nagahara: {
    name: "永原康史教授",
    img: require("@/assets/imgs/shindan/teachers/nagahara.jpg"),
    about: "グラフィックデザイナー。デジタルメディアや展覧会のアートディレクションも手がけ、メディア横断的なデザインを推進している。MMCAマルチメディアグランプリ最優秀賞など受賞。竹尾アーカイヴズディレクター。多摩美術大学メディアセンター所長。",
    kamoku: [
      "インフォグラフィックス演習",
      "メディアデザインI,II（永原ゼミ）",
      "メディアとデザインゼミ",
      "卒業研究制作",
      "メディアデザイン論",
      "大学院情報デザイン特論",
      "大学院デザイン研究「メディアデザイン研究グループ」"
    ],
    igaina: [
      "座右の銘  /  地上とは思い出ならずや",
      "好きな食べ物  /  ハムエッグ(ふたつ目玉)",
      "嫌いな食べ物  /  きのこ",
      "1つだけ無人島に持っていくなら  /  着替え あるいは、歯ブラシ",
      "休日何をしているか  /  何もしない",
      "何に癒されるか  /  おいしいワイン あるいは、藤原さくらの弾き語り"
    ]
  },
  kusunoki: {
    name: "楠房子教授",
    img: require("@/assets/imgs/shindan/teachers/kusunoki.jpg"),
    about: "学習のデザインを中心としたコンピュータと人間のインタラクションを担当している。IC タグ、携帯電話等のモバイル機器を用いたユビキタスのデザインを研究、一方でエンターテイメント研究も行なっている。また「子供サントリー」「赤ちゃんの事故予防」「博物館における展示支援」など国内での産官学のプロジェクト型の共同研究も多い。情報処理学会発表賞受賞等、国内外を問わず学会での受賞多数。博士（工学）。",
    kamoku: [
      "知識と表現演習",
      "社会デザインII（楠ゼミ）",
      "エンタテイメントとデザインゼミ",
      "卒業研究制作",
      "大学院情報デザイン特論",
      "大学院デザイン研究「メディアデザイン研究グループ」"
    ],
    igaina: [
      "座右の銘  /  果報は寝て待て",
      "好きな食べ物  /  アイスのシロクマ君",
      "嫌いな食べ物  /  にんじん",
      "一つだけ無人島に持って行くなら  /  日焼け止め",
      "休日何をしているか  /  好きなアニメ見ています。",
      "何に癒されるか  /  ハシビロコウの動画に癒されています。"
    ]
  },
  taki: {
    name: "瀧知惠美講師",
    img: require("@/assets/imgs/shindan/teachers/taki.jpg"),
    about: "多摩美術大学情報デザイン学科卒業。東京藝術大学デザイン科修士課程修了。ヤフー株式会社にて複数サービスのUXデザインを担当し、所属部署でUXデザインチームのマネージャーを経験した後、UXデザイン推進活動として研修やUX導入の実務支援を行う。2020年からミミクリデザイン（現在株式会社MIMIGURI）にて、自社サービスCULTIBASEのサービスデザインを担当すると同時に、クライアントワークでは事業開発と組織開発の組み合わせ方を実践と研究の両軸を重視しながら探究している。",
    kamoku: [
      "情報設計演習"
    ],
    igaina: [
      "座右の銘  /  継続は力なり",
      "好きな食べ物  /  スープストック東京のスープ",
      "嫌いな食べ物  /  そんなに無い",
      "一つだけ無人島に持って行くなら  /  防寒具",
      "休日何をしているか  /  観葉植物の世話をする",
      "何に癒されるか  /  観葉植物に癒される"
    ]
  }
};